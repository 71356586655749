import Axios from 'axios';
import Cookies from 'js-cookie';

import AppConfig from 'config/App';

const request = is_external => {
  const ixn_auth = Cookies.getJSON('ixn') || {};
  const token = window.HEXURE_AQ_CONFIG.id;

  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  };

  if (!is_external) {
    headers['ANNUITY-QUOTER-TOKEN'] = token;
    headers['access-token'] = ixn_auth.access_token;
    headers['client'] = ixn_auth.client;
    headers['uid'] = ixn_auth.uid;
  }

  return Axios.create({
    baseURL: AppConfig.API_BASE_URL,
    headers
  });
};

const AnnuitiesApi = {
  loadQuoter() {
    return request().get(`/v1/annuity_quoter/${window.HEXURE_AQ_CONFIG.id}`);
  },

  loadCarriers(params) {
    return request()
      .get('/v1/annuity_carriers', { params })
      .then(response => response.data);
  },

  loadProduct(params) {
    return request()
      .get('v1/products', { params })
      .then(response => response.data);
  },

  loadProductFeatures() {
    return request()
      .get('v1/product_features')
      .then(response => response.data);
  },

  loadAmbestRecord(carrier_id) {
    return request()
      .get('/v1/ambest_records/', { params: { carrier_id } })
      .then(response => {
        return response;
      });
  },

  loadMarketIndicies() {
    return request().get('/v1/market_indicies');
  },

  loadRateTerms() {
    return request().get('/v1/available_initial_rate_terms');
  },

  loadProductDetail(quoteId) {
    return request().get(`/v1/annuity_quotes/product-detail/${quoteId}`);
  },

  getQuotes(params) {
    return request()
      .post('/v1/annuity_quotes/', params)
      .then(({ data }) => {
        return data;
      })
      .catch(() => {
        throw new Error({ message: 'No Quotes Found' });
      });
  },

  generateQuotesPdf(params) {
    return request().post('/v1/annuity_pdfs', params);
  },
  requestForms(params) {
    return request().post('/v1/forms/request_forms', params);
  },

  postToWebhook(url, params) {
    return request(true).post(url, params);
  },

  createEticket(params) {
    return request().post('/v1/etickets', params);
  },

  loadEapp(id) {
    return request(false, true).get(`/v1/e_apps/${id}`);
  },

  createEApp(params) {
    return request(false, true).post('/v1/e_apps', params);
  },

  updateEApp(id, params) {
    return request(false, true).put(`/v1/e_apps/${id}`, params);
  },

  login(params) {
    return request().post('/v1/members/login', params);
  },
  signup(params) {
    return request().post('/v1/members', params);
  },
  updateUser(id, params) {
    return request().put(`/v1/members/${id}`, params);
  },
  logout() {
    return request().delete('/v1/members/logout');
  },

  loadMember(member_id) {
    return request().get(`/v1/members/${member_id}`);
  }
};

export default AnnuitiesApi;
