import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
//import { mdiOpenInNew, mdiChartBoxPlusOutline, mdiFileDocumentOutline, mdiLink, mdiInformationOutline, mdiFileDownloadOutline, mdiEmailOutline, mdiForumOutline } from '@mdi/js';

//import ActionCard from 'shared/ActionCard';
import { Colors, FontSizes, FontWeights } from 'constants/Clementine';
import Moment from 'moment';

import OpenUrlModal from './OpenUrlModal';
import FormUtils from 'utils/Form';
import PostToUrlModal from './PostToUrlModal';
import FirelightEappModal from './FirelightEappModal';
import FirelightEticketModal from './FirelightEticketModal';
import Loader from '../shared/Loader';
import { ProductContext } from './Context';
import AnnuitiesApi from '../../utils/AnnuitiesApi';
import RequestFormsModal from './RequestFormsModal';
import ShareQuotesModal from './ShareQuotesModal';
import { Modal } from '@hexure/ui';
import PdfActions from '../shared/PdfActions';
// import PdfActions from '../shared/PdfActions';
//import { CUSTOM_ACTIONS } from '../../constants/App';

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 40px;
`;

const QUOTER_CONFIG = window.HEXURE_AQ_CONFIG;
const fl_disabled_action_types = ['applicant', 'firelight', 'psg_eapp'];
const fl_restricted_action_types = ['anico', 'nmb_term_eticket', 'nmb_whole_life_eticket', 'paclife', 'protective', 'request_forms', 'sbli', 'standard_packet'];

const QuoteActionModal = ({ actions, onClose, quote, quoteData }) => {
  const context = useContext(ProductContext);
  const [modal, setModal] = useState('actions');
  const [action, setAction] = useState(null);
  const [hovering_id, setHoveringId] = useState(null);
  const [to, setTo] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [firelight_eapp, setFirelight_Eapp] = useState(null);
  const styles = () => {
    return {
      content: {
        padding: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'wrap'
      },
      box_button: {
        width: 120,
        height: 120,
        margin: '0 10px 20px',
        padding: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        border: '1px solid #ccc',
        borderRadius: 4,
        cursor: 'pointer'
      },
      box_button_selected: {
        border: `2px solid ${Colors.GREEN.hex}`
      },
      box_button_image: {
        height: 40,
        width: 40,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      },
      box_button_icon: {
        fontSize: 40
      },
      box_button_label: {
        marginTop: 10,
        fontSize: FontSizes.MEDIUM,
        fontWeight: FontWeights.MEDIUM
      }
    };
  };
  const meta_data = {
    default: {
      icon: 'mdi-open-in-new',
      modal: 'open_url'
    },
    default_modal: {
      icon: 'mdi-link',
      modal: 'open_url'
    },
    webhook_post: {
      icon: 'mdi-open-in-new',
      modal: 'post_to_url'
    },
    webhook_post_modal: {
      icon: 'mdi-link',
      modal: 'post_to_url'
    },
    simple_url: {
      icon: 'mdi-open-in-new',
      modal: 'open_url'
    },
    request_forms: {
      icon: 'mdi-information-outline',
      modal: 'Request Forms'
    },
    simple_url_modal: {
      icon: 'mdi-link',
      modal: 'open_url'
    },
    download_quotes_pdf: {
      icon: 'mdi-file-download-outline',
      modal: 'Download Quote PDF'
    },
    send_quote_as_email: {
      icon: 'mdi-email-outline',
      modal: 'Email Quote PDF'
    },
    send_quote_as_text: {
      icon: 'mdi-forum-outline',
      modal: 'Text Quote PDF'
    },
    firelight_illustration: {
      icon: 'mdi-chart-box-plus-outline',
      modal: 'firelight_illustration'
    },
    firelight_e_app: {
      icon: 'mdi-file-document-edit-outline',
      modal: 'firelight_eapp'
    },
    firelight_e_ticket: {
      icon: 'mdi-file-document-outline',
      modal: 'firelight_eticket'
    }
  };

  const quote_actions = actions.filter(a => a.carrier_ids.includes(quote.carrier_id) && a.product_ids.includes(quote.product_id));
  const onActionClick = (modal, action) => {
    setAction(action);
    setModal(modal);
  };
  const setFirelightEapp = firelight_eapp => {
    setFirelight_Eapp(firelight_eapp);
  };

  const handleFireLightQuoteAction = onClick => {
    const { client } = quoteData;
    const { date_of_birth, current_age, nearest_age } = client;

    let ages_from_dob = {};
    if (date_of_birth) {
      ages_from_dob = FormUtils._getAgesFromDob(date_of_birth);
    }

    const calculated_current_age = date_of_birth ? ages_from_dob.calculated_current_age : current_age;
    const calculated_nearest_age = date_of_birth ? ages_from_dob.calculated_nearest_age : nearest_age;

    setLoading(true);
    generateQuotePdf(quote)
      .then(response => {
        onClick({
          agent: context.agent,
          client: context.client,
          quote: { ...quote, gender: context.client.gender, pdf_url: response.data.url },
          quoted_config: {
            ...quoteData,
            date_of_birth: date_of_birth ? Moment(date_of_birth).format('YYYY-MM-DD') : null,
            current_age: calculated_current_age,
            nearest_age: calculated_nearest_age
          }
        });
        setLoading(false);
        onclose();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const generateQuotePdf = quote => {
    const agent_email = context?.agent.email || '';
    const agent_license_number = context?.agent.insurance_license_number || '';
    const agent_phone_number = context?.agent.phone || '';
    const client_name = `${context?.client.first_name || ''} ${context?.client.last_name || ''}`.trim();

    const payload = {
      quote_guids: [quote.id || ''],
      template: 'default',
      pdf_action: 'download',
      show_agent_info: true,
      agent_email,
      from: agent_email,
      agent_first_name: context?.agent.first_name || '',
      agent_last_name: context?.agent.last_name || '',
      agent_license: agent_license_number,
      agent_phone: agent_phone_number,
      to,
      client_name,
      phone_number,
      subject,
      message
    };

    return AnnuitiesApi.generateQuotesPdf(payload);
  };
  const handleHover = hovering_id => {
    setHoveringId(hovering_id);
  };

  const fireLightActionIsValid = quote_action => {
    const embed_config_quote_actions = context?.aaq.embed_config_quote_actions;
    const fl_apply_quote_action = embed_config_quote_actions?.find(action => action.type === 'fl_apply');

    if (fl_disabled_action_types?.includes(quote_action.action_type)) {
      return false;
    } else if (fl_apply_quote_action && fl_apply_quote_action.enabled_quote_guids?.includes(quote.guid) && fl_restricted_action_types?.includes(quote_action.action_type)) {
      return false;
    } else {
      return true;
    }
  };

  switch (modal) {
    case 'post_to_url':
      return <PostToUrlModal data={{ quote, ...quoteData }} errorMsg={action.error_msg} onClose={setModal.bind(null, null)} successMsg={action.success_msg} url={action.url} />;
    case 'open_url':
      return <OpenUrlModal data={action.action_type === 'simple_url' ? null : { quote, ...quoteData }} inline={action.open_in_modal} onClose={setModal.bind(null, null)} url={action.url} />;
    case 'firelight_eapp':
      if (action.action_data.activity === 'e_app') {
        return <FirelightEappModal action={action} e_app={firelight_eapp} onClose={setModal.bind(null, null)} onSave={setFirelightEapp} quote={quote} />;
      } else {
        return <Loader />;
      }
    case 'firelight_eticket':
      return <FirelightEticketModal action={action} onClose={setModal.bind(null, null)} onSave={setModal.bind(null, null)} quote={quote} />;
    case 'Request Forms':
      return <RequestFormsModal onBackClick={setModal.bind(null, null)} onClose={setModal.bind(null, null)} quote={quote} />;

    case 'Email Quote PDF':
      return <PdfActions agent={context?.agent || ''} client={context?.client || ''} solve={context?.solve || ''} onClose={onClose} selectedQuotes={quote} mode='action' type='email' />;

    case 'Text Quote PDF':
      return <PdfActions agent={context?.agent || ''} client={context?.client || ''} solve={context?.solve || ''} onClose={onClose} selectedQuotes={quote} mode='action' type='text' />;

    case 'Download Quote PDF':
      return <PdfActions agent={context?.agent || ''} client={context?.client || ''} solve={context?.solve || ''} onClose={onClose} selectedQuotes={quote} mode='action' type='download' />;

    default:
      return (
        <Modal onClose={onClose} title='Select an Action'>
          {loading ? (
            <Loader />
          ) : (
            <Content>
              {context?.aaq?.embed_config_quote_actions
                ? context?.aaq?.embed_config_quote_actions.map((action, index) => {
                    if (action.enabled_quote_guids.includes(quote.guid)) {
                      return (
                        <div
                          key={action.id || index}
                          onClick={QUOTER_CONFIG.embed_context === 'fire_light' ? handleFireLightQuoteAction.bind(null, action.on_click, quote) : action.on_click.bind(null, quote)}
                          onMouseEnter={handleHover.bind(null, action.id || index)}
                          onMouseLeave={handleHover.bind(null, null)}
                          style={Object.assign({}, styles().box_button, (hovering_id === action.id || hovering_id === index) && styles().box_button_selected)}
                        >
                          <i className={`mdi mdi-${action.icon}`} style={styles().box_button_icon} />
                          <div style={styles().box_button_label}>{action.label}</div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })
                : null}

              {quote_actions.map(action => {
                const type = `${action.action_type}${action.open_in_modal ? '_modal' : ''}${action.action_data?.activity ? `_${action.action_data.activity}` : ''}`;
                const data = meta_data[type];
                if (QUOTER_CONFIG.embed_context !== 'fire_light' || fireLightActionIsValid(action)) {
                  return (
                    <div
                      key={action.id}
                      onClick={onActionClick.bind(null, data.modal, action)}
                      onMouseEnter={handleHover.bind(null, action.id || index)}
                      onMouseLeave={handleHover.bind(null, null)}
                      style={Object.assign({}, styles().box_button, hovering_id === action.id && styles().box_button_selected)}
                    >
                      {action.icon && action.icon.url ? (
                        <div style={Object.assign({ backgroundImage: 'url(' + action.icon.url + ')' }, styles().box_button_image)} />
                      ) : (
                        <i className={`mdi ${data.icon}`} style={styles().box_button_icon} />
                      )}
                      <div style={styles().box_button_label}>{action.label.length ? action.label : data.label}</div>
                    </div>
                  );
                }
                return null;
              })}
            </Content>
          )}
        </Modal>
      );
  }
};

QuoteActionModal.propTypes = {
  actions: PropTypes.array,
  onClose: PropTypes.func,
  quote: PropTypes.object,
  quoteData: PropTypes.object
};

export default QuoteActionModal;
